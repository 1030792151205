/* IconRow.js */

import React, { useState, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { sabeDev} from './global';


const IconRow = () => {
  const iconPaths = useMemo(() => [
    '/imagens/n1.png',
    '/imagens/n2.png',
    '/imagens/n3.png',
    '/imagens/n4.png',
    '/imagens/n5.png',
    '/imagens/n6.png',
    '/imagens/n7.png',
    '/imagens/n8.png',
    '/imagens/n9.png',
  ], []);

  const altIconPaths = [
    './imagens/s1.png',
    './imagens/s2.png',
    './imagens/s3.png',
    './imagens/s4.png',
    './imagens/s5.png',
    './imagens/s6.png',
    '/imagens/s7.png',
    '/imagens/s8.png',
    '/imagens/s9.png',
  ];

  const additionalIconPaths = [
    [
      './imagens/m1.png',
      './imagens/p1.png'
    ],
    [
      './imagens/m2.png',
      './imagens/p2.png',
      '/imagens/c2.png'
    ],
    [
      './imagens/m3.png',
      './imagens/p3.png'
    ],
    [
      './imagens/m4.png',
      './imagens/p4.png'
    ],
    [
      './imagens/m5.png',
      './imagens/p5.png',
      '/imagens/c5.png'
    ],
    [
      './imagens/m6.png',
      './imagens/p6.png'
    ],
    [
      '/imagens/m7.png',
      '/imagens/p7.png'
    ],
    [
      '/imagens/m8.png',
      '/imagens/p8.png'
    ],
    [
      '/imagens/m9.png',
      '/imagens/p9.png',
      '/imagens/c9.png'
    ]
    // Add the path for additional icons below the first row here
  ];

  const additionalIconLinks = [
    [
      '.gameseditoralt.com.br/1Ano/matematica/',
      '.gameseditoralt.com.br/1Ano/portugues/'
    ],
    [
      '.gameseditoralt.com.br/2Ano/matematica/',
      '.gameseditoralt.com.br/2Ano/portugues/',
      '.gameseditoralt.com.br/2Ano/ciencias/'
    ],
    [
      '.gameseditoralt.com.br/3Ano/matematica/',
      '.gameseditoralt.com.br/3Ano/portugues/'
    ],
    [
      '.gameseditoralt.com.br/4Ano/matematica/',
      '.gameseditoralt.com.br/4Ano/portugues/'
    ],
    [
      '.gameseditoralt.com.br/5Ano/matematica/',
      '.gameseditoralt.com.br/5Ano/portugues/',
      '.gameseditoralt.com.br/5Ano/ciencias/'
    ],
    [
      '.gameseditoralt.com.br/6Ano/matematica/',
      '.gameseditoralt.com.br/6Ano/portugues/'
    ],
    [
      '.gameseditoralt.com.br/7Ano/matematica/',
      '.gameseditoralt.com.br/7Ano/portugues/'
    ],
    [
      '.gameseditoralt.com.br/8Ano/matematica/',
      '.gameseditoralt.com.br/8Ano/portugues/'
    ],
    [
      '.gameseditoralt.com.br/9Ano/matematica/',
      '.gameseditoralt.com.br/9Ano/portugues/',
      '.gameseditoralt.com.br/9Ano/ciencias/'
    ],
    // Adicione os links adicionais correspondentes aos ícones additionalIconPaths aqui
  ];

  // Obtém o ícone selecionado anteriormente do cookie ou define altIconPaths[0] como padrão
  const [selectedIcon, setSelectedIcon] = useState(() => {
    const prevSelectedIcon = Cookies.get('selectedIcon');

    if (prevSelectedIcon === undefined || !altIconPaths.includes(altIconPaths[parseInt(prevSelectedIcon)])) {
      return 0;
    }

    return parseInt(prevSelectedIcon);
  });

  // Estado para mostrar o ícone de filtro por padrão na primeira navegação
  const [showAdditionalIcon, setShowAdditionalIcon] = useState(false);
  const [showDefaultFilterIcon, setShowDefaultFilterIcon] = useState(false);
  const [showSelectionIcons, setShowSelectionIcons] = useState(true);

  useEffect(() => {
    // Salva o ícone selecionado em um cookie toda vez que ele é alterado
    if (selectedIcon !== null) {
      Cookies.set('selectedIcon', selectedIcon.toString());
    }

    // Define o estado showDefaultFilterIcon como false após a montagem inicial
    setShowDefaultFilterIcon(false);

    // Verifica se o ícone selecionado é '/imagens/n9.png' para mostrar a imagem adicional
    setShowAdditionalIcon(iconPaths[selectedIcon] === '/imagens/n9.png');

    // Verifica se o ícone selecionado é diferente de null para mostrar os ícones de seleção
    setShowSelectionIcons(selectedIcon !== null);
  }, [selectedIcon, iconPaths]); // Adicionando iconPaths à lista de dependências



  // Função para lidar com o clique no ícone "Página Inicial"
  // const handleHomeIconClick = () => {
  //   const homeIconLink = "https://sabebrasilteste.gameseditoralt.com.br/";
  //   window.location.href = selectedIcon === 0 ? homeIconLink : altIconLinks[selectedIcon];
  // };

  const handleIconClick = (index) => {
    setSelectedIcon(index);

    // Guarda o estado da seleção do ícone no histórico do navegador
    window.history.pushState({ selectedIcon: index, prevSelectedIcon: selectedIcon }, '');
  };

  const handleArrowClick = (direction) => {
    setSelectedIcon((prevSelectedIcon) => {
      if (direction === 'left') {
        return prevSelectedIcon === 0 ? null : prevSelectedIcon - 1;
      } else {
        return prevSelectedIcon === iconPaths.length - 1 ? null : prevSelectedIcon + 1;
      }
    });
  };

  return (
    <div className="container">
      <div className="icon-row">
        {/* Adicione a seta para a esquerda antes dos ícones */}
        {showSelectionIcons && selectedIcon !== 0 ? (
          <img
            src="/imagens/SetaEsquerda.png"
            alt="Seta Esquerda"
            className="arrow-icon"
            onClick={() => handleArrowClick('left')}
          />
        ) : (
          <img
            src="/imagens/SetaCinzaEsquerda.png"
            alt="Seta Desabilitada"
            className="no-hover"
          />
        )}

        {showSelectionIcons &&
          iconPaths.map((iconPath, index) => (
            <img
              key={index}
              src={selectedIcon === index ? altIconPaths[index] : iconPath}
              alt=""
              className={`icon-image ${selectedIcon === index ? 'no-shadow' : ''} ${index >= iconPaths.length ? 'no-shadow' : ''}`}
              onClick={() => handleIconClick(index)}
            />
          ))}

        {/* Adicione a seta para a direita depois dos ícones */}
        {showSelectionIcons && selectedIcon !== iconPaths.length - 1 ? (
          <img
            src="/imagens/SetaDireita.png"
            alt="Seta Direita"
            className="arrow-icon"
            onClick={() => handleArrowClick('right')}
          />
        ) : (
          <img
            src="/imagens/SetaCinzaDireita.png"
            alt="Seta Desabilitada"
            className="no-hover"
          />
        )}
      </div>
        <div className="additional-icon-row">
        {showSelectionIcons && additionalIconPaths[selectedIcon] &&
          additionalIconPaths[selectedIcon].map((iconPath, idx) => (
            <a key={idx} href={sabeDev ? 'https://sabebrasildev' + additionalIconLinks[selectedIcon][idx] : 'https://sabebrasil' + additionalIconLinks[selectedIcon][idx]}>
              <img src={iconPath} alt="" className="additional-icon-image" />
            </a>
          ))
        }
      </div>
    </div>
  );
};

export default IconRow;